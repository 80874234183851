import React, { Component } from 'react';
import SinonimoDataService from "../services/sinonimo.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';

const columns = [
  { name: '#', selector: 'id', sortable: true, },
  { name: 'Descrição', selector: 'descricao', sortable: true, wrap: true, compact: true, },
  { name: 'Ativo', selector: 'ativo', sortable: true, right: true, omit: false, compact: true, cell: row => <> {row.ativo === true ? 'X' : ''}</> },
  { name: 'Ações', compact: true, cell: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>Edit</small></Button>}</>, right: true },
]
const handleButtonClick = (state) => {
  window.location = "/editar-sinonimos/" + state.id;
};

class Sinonimos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sinonimos: [],
      message: ""
    };

  }

  handleLogout = () => {

  }

  componentDidMount() {
    this.getSinonimos();
  }

  getSinonimos() {
    SinonimoDataService.getAll()
      .then(response => {
        this.setState({
          sinonimos: response.data
        });
      })
      .catch(e => {
        this.setState({
          errorMessage: e.response.data.message
        });
      });
  }


  render() {


    return (

      <div className="page-wrapper" >
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Outros nomes" ></Header>
        <section className="course-one course-one__teacher-details home-one pt-2">
          <Container>

            <Container fluid={true}>
              <Row className={"pull-right"}>
                <Col className={"py-2 "}>
                  <Link to="/add-sinonimos" className="btn pull-right btn-info botao-acao" >+</Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataTableExtensions exportHeaders={true}
                    columns={columns}
                    data={this.state.sinonimos}>
                    <DataTable
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover

                    />
                  </DataTableExtensions>
            <>{this.state.errorMessage}</>
                </Col>
              </Row>
            </Container>

          </Container>{/*<!-- /.container -->*/}
        </section>

        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default Sinonimos;