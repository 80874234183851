import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import Header from './Common/Header';
import NavTop from './Common/NavTop';
import NavMain from './Common/NavMain';
import Footer from './Common/Footer';
import { TextInput } from './Common/TextInput';
import FooterMenu from './Common/FooterMenu';
import SinonimoDataService from "../services/sinonimo.service";
import {  Col, Container, Row } from 'react-bootstrap';
// import CheckBox from './Common/CheckBox';
import { Link } from 'react-router-dom';
// import Toogles from './Common/Toogles';
// import Switch from './Common/Switch';
// import { showMessage, showMessageInfo } from '../utils';
import TextArea from './Common/TextArea'

class EditarSinonimo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // sinonimo: [],
            // nome: "",
            NomeBase: "",
            NomeDicionario: "",
            NomeOriginal: "",
            ClassificacaoGeral: "",
            ContextoMusical: "",
            Curiosidades: "",
            DadosGeograficos: "",
            Idioma: "",
            Linkaudio: "",
            Linkfoto1: "",
            Linkfoto2: "",
            Linkilustracao: "",
            Linkvideo: "",
            PrincipaisMateriais: "",
            Sinonimos: "",
            TecnicaConvencional: "",
            TecnicaEstendida: "",
            Variacoes: "",
            // audio: "",
            audioexecucao: "",
            audiofonte: "",
            // categoria: "",
            classificacao: "",
            createdAt: "",
            fotografia1: "",
            fotografia1fonte: "",
            fotografia2: "",
            fotografia2fonte: "",
            id: "",
            // idCategoria: "",
            // imagem: "",
            // texto: "",
            updatedAt: "",
            // video: "",
            videoexecucao: "",
            videofonte: "",
            sample: 0,
            published: 0,
            instrum: {},
        };
        this.handleDataChange = this.handleDataChange.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        // this.handleDataClassificacaoGeralChange = this.handleDataClassificacaoGeralChange.bind(this);
        // this.handleDataPrincipaisMateriaisChange = this.handleDataPrincipaisMateriaisChange.bind(this);
        // this.handleDataVariacoesChange = this.handleDataVariacoesChange.bind(this);
        // this.handleDataTecnicaConvencionalChange = this.handleDataTecnicaConvencionalChange.bind(this);
        // this.handleDataTecnicaEstendidaChange = this.handleDataTecnicaEstendidaChange.bind(this);
        // this.handleDataDadosGeograficosChange = this.handleDataDadosGeograficosChange.bind(this);
        // this.handleDataContextoMusicalChange = this.handleDataContextoMusicalChange.bind(this);
        this.handleDescricaoChange = this.handleDescricaoChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onClickPublished = this.onClickPublished.bind(this);
        this.onClickSample = this.onClickSample.bind(this);
        this.closeHandle = this.closeHandle.bind(this);
        this.entendiHandle = this.entendiHandle.bind(this);
    }
    onChangeText = (event) => {

        // //console.log(event);
    }
    onClickPublished = (event) => {
        if (this.state.published === 1) {
            this.setState({
                published: 0
            });
        } else {
            this.setState({
                published: 1
            });
        }
    }


    onClickSample = (event) => {
        if (this.state.sample === 1) {
            this.setState({
                sample: 0
            });
        } else {
            this.setState({
                sample: 1
            });
        }
    }
    entendiHandle = e => {
        e.preventDefault();
        window.location = './home';
        console.log('close button clicked')
    }

    closeHandle = e => {
        e.preventDefault();
        console.log('close button clicked')
    }
    async handleSubmit(event) {
        event.preventDefault();

        let data = JSON.stringify(this.state);
        // //console.log(data);

        SinonimoDataService.update(this.state.id, data)
            .then(response => {
                alert(response.data.message);
                window.location = './sinonimos';
                // showMessage('', response.data.message, true, this.entendiHandle, 'Entendi', this.closeHandle)

            })
            .catch(e => {
                console.log(e);
                // this.setState({ itemserror: this.state.itemserror.concat(data) })
                // this.setState({ counterror: this.state.counterror + 1 });
            });

    };

    handleLogout = () => {

    }
    handleDescricaoChange(event, editor) {
        this.setState({ descricao: editor.getData() });
    }
    handleDataPrincipaisMateriaisChange(event, editor) {
        this.setState({ PrincipaisMateriais: editor.getData() });
    }
    handleDataVariacoesChange(event, editor) {
        this.setState({ Variacoes: editor.getData() });
    }
    handleDataTecnicaConvencionalChange(event, editor) {
        this.setState({ TecnicaConvencional: editor.getData() });
    }
    handleDataTecnicaEstendidaChange(event, editor) {
        this.setState({ TecnicaEstendida: editor.getData() });
    }
    handleDataDadosGeograficosChange(event, editor) {
        this.setState({ DadosGeograficos: editor.getData() });
    }
    handleDataContextoMusicalChange(event, editor) {
        this.setState({ ContextoMusical: editor.getData() });
    }
    handleDataCuriosidadesChange(event, editor) {
        this.setState({ Curiosidades: editor.getData() });
    }
    handleDataChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    componentDidMount() {
        this.getSinonimo();
        // //console.log(this.state.instrum);
    }

    carregarSinonimosIdiomas() {
        // //console.log('carregarSinonimosIdiomas')
        //console.log(this.state.instrum);
    }

    getSinonimo() {
        let sinonimo = (this.props.match.params.sinonimo !== undefined ? this.props.match.params.sinonimo : '-');
        this.setState({ letra: sinonimo.toUpperCase() })
        SinonimoDataService.get(sinonimo)
            .then(response => {
                //console.log('carregarSinonimos')
                //console.log(response.data)
                //console.log(response.data.length)

                if (response.data) {
                    this.setState({

                        // nome: response.data.nome,
                        // NomeBase: response.data.NomeBase,
                        id: response.data.id,
                        descricao: response.data.descricao,
                        published: response.data.published,
                        Idioma: response.data.Idioma
                    });
                    this.carregarSinonimosIdiomas();
                }
                else {
                    this.setState({ Error: true });
                }
            })
            .catch(e => {
                //console.log(e);
            });
    }

    render() {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Editando sinonimo" ></Header>
                <Container className='pb-5'>
                    <form onSubmit={this.handleSubmit}>


                        <Row>
                            <Col sm={12} className="form-group">
                                <TextArea onChange={this.handleDataChange} labelValue={"descricao"} id={"descricao"} name={"descricao"}   value={this.state.descricao} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Idioma"} id={"Idioma"} name={"Idioma"} defaultValue={this.state.Idioma} value={this.state.Idioma} className="form-control" />
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col sm={6} className="form-group">
                                <Toogles
                                    id="published"
                                    name="published"
                                    type="published"
                                    placeholder=""
                                    labelValue="published"
                                    isChecked={parseInt(this.state.published) === 1}
                                    FunctionClickCall={this.onClickPublished}
                                    FunctionChangeCall={this.onChangeText}
                                />

                            </Col>
                        </Row> */}


                        <Row className="row">
                            <Col className="form-group col-md-4">
                                <div className="">
                                    <button className="btn btn-primary mr-2" type="submit">Salvar sinonimo</button>
                                    <Link to="/sinonimos" className="btn btn-warning" type="button">Voltar</Link>
                                </div>
                            </Col>
                            <Col className="form-group col-md-4">
                                <div className="">

                                </div>
                            </Col>
                        </Row>


                    </form>
                </Container>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
export default EditarSinonimo;