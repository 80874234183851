import React, { Component } from 'react';
import reactLogo from '../../assets/images/logo.png';
import { Link, NavLink, } from 'react-router-dom';
import { Col, Container, Nav, NavDropdown, Row } from 'react-bootstrap';
import { isLogin, logout } from '../../utils';
class NavMain extends Component {
    constructor(props) {
        super(props);
        ;
    }

    stricky = () => {
        var $ = require("jquery");

        if ($('.stricky').length) {
            $('.stricky').addClass('original').clone(true).insertAfter('.stricky').addClass('stricked-menu').removeClass('original');
        }
    }
    componentDidMount() {
        this.stricky();
    }
    handleLogout = () => {
        logout();
        this.setState({
            isLogin: false
        })
        window.location.reload();
    }
    render() {
        return (
            <Container>
                <Row>

                    <Col>


                        <header className="site-header site-header__header-one ">



                            {isLogin()
                                ?
                                <Nav
                                    activeKey="/home"
                                    className="navbar navbar-expand-lg navbar-light header-navigation stricky  navigation-box   "
                                >
                                    <div className="container clearfix col   px-0">
                                        {/*<!-- Brand and toggle get grouped for better mobile display -->*/}
                                        <div className="logo-box clearfix pt-3">
                                            <Link to="/" className="navbar-brand" >
                                                <img src={reactLogo} className="main-logo" width="160" alt="Awesome" />
                                            </Link>

                                            <button className="menu-toggler" data-target=".main-navigation">
                                                <span className="kipso-icon-menu"></span>
                                            </button>
                                        </div>{/*<!-- /.logo-box -->*/}


                                    </div>
                                    {/*<!-- /.container -->*/}
                                    <NavDropdown title="Cadastros" id="nav-dropdown" menuRole="text-link" className="text-link" >
                                        <NavDropdown.Item href="/planos"       >Planos                                 </NavDropdown.Item>
                                        <NavDropdown.Item href="/instrumentos" >Instrumentos                           </NavDropdown.Item>
                                        <NavDropdown.Item href="/glossario"    >Glossário                              </NavDropdown.Item>
                                        <NavDropdown.Item href="/sinonimos"    >Outros Nomes                           </NavDropdown.Item>
                                        <NavDropdown.Item href="/politica"     >Política de Privacidade                </NavDropdown.Item>
                                        <NavDropdown.Item href="/termos"       >Termos de uso e condições gerais       </NavDropdown.Item>
                                        <NavDropdown.Item href="/usuarios"     >Usuários                               </NavDropdown.Item>
                                        <NavDropdown.Item href="/codigos"      >Códigos                                </NavDropdown.Item>
                                        <NavDropdown.Item href="/newsletters"  >Newsletters                            </NavDropdown.Item>



                                    </NavDropdown>

                                    <NavDropdown title="Importação" id="nav-dropdown" menuRole="text-link" className="text-link" >
                                        <NavDropdown.Item href="/importInstrumento" >Importação de Instrumento        </NavDropdown.Item>
                                        <NavDropdown.Item href="/importGlossario" >Importação de Glossário        </NavDropdown.Item>
                                        <NavDropdown.Item href="/importSinonimo" >Importação de Outros Nomes</NavDropdown.Item>

                                    </NavDropdown>
                                    <Nav.Item>
                                        <NavLink tag={Link} className="text-link" to="#" onClick={() => this.handleLogout()}>Sair</NavLink>
                                    </Nav.Item>
                                </Nav>


                                : <></>
                            }
                        </header>
                    </Col>
                </Row>

            </Container>
        );
    }
}
export default NavMain;
