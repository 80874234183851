import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import * as XLSX from 'xlsx'
import InstrumentoDataService from "../services/instrumento.service";

import Header from './Common/Header';
import NavTop from './Common/NavTop';
import NavMain from './Common/NavMain';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
// import BannerHome from './BannerHome';
// import BannerPlanos from './BannerPlanos';
// import MailChimp from './mailchimp';
// import SejaBemVindo from './SejaBemVindo';
// import Fase01 from './Fase01';
// import Planos from './Planos';
export default class ImportInstrumento extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            Aguardando: true,
            itemserror: [],
            count: 0,
            counterror: 0,
            countgeneral: 0,
            countskip: 0,
            StartImportacao: false
        }

    }
    SalvarInstrumentos(data) {
        // debugger
        // if (data.Idioma === 'START')
        this.setState({ StartImportacao: true })
        // console.log(data)
        if (this.state.StartImportacao && data.NomeOriginal !== 'NomeOriginal') {

            this.setState({ countgeneral: this.state.countgeneral + 1 });
            InstrumentoDataService.create(data)
                .then(response => {
                    this.setState({ items: this.state.items.concat(data) })
                    this.setState({ count: this.state.count + 1 });

                })
                .catch(e => {
                    // //console.log(e);
                    this.setState({ itemserror: this.state.itemserror.concat(data) })
                    this.setState({ counterror: this.state.counterror + 1 });
                });
        }
    }
    render() {


        const readExcel = (file) => {
            this.setState({
                count: 0,
                counterror: 0,
                countgeneral: 0,
                countskip: 0
            })
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file)

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: 'buffer' });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];

                    const data = XLSX.utils.sheet_to_json(ws);
                    resolve(data);
                };
                fileReader.onerror = (error) => {
                    reject(error);
                }
            })
            promise.then((data) => {
                var stringified = JSON.stringify(data);
                stringified = stringified.replaceAll('Nome Original', 'NomeOriginal')
                    .replaceAll('Nome Dicionário', 'NomeDicionario')
                    .replaceAll('"IDIOMA"', '"Idioma"')
                    .replaceAll('Classificação geral', 'ClassificacaoGeral')
                    .replaceAll('Sinônimos', 'Sinonimos')
                    .replaceAll('Descrição do formato / Principais materiais', 'PrincipaisMateriais')
                    .replaceAll('Variações/ versões', 'TecnicaEstendida')
                    .replaceAll('Técnica estendida', 'Variacoes')
                    .replaceAll('Técnica convencional', 'TecnicaConvencional')
                    .replaceAll('Dados geográficos/dados temporais', 'DadosGeograficos')
                    .replaceAll('Contexto musical', 'ContextoMusical')
                    .replaceAll('Curiosidades', 'Curiosidades')
                    .replaceAll('fotografia 1', 'fotografia1')
                    .replaceAll('fotografia1 fonte', 'fotografia1fonte')
                    .replaceAll('fotografia 1 fonte', 'fotografia1fonte')
                    .replaceAll('fotografia 2', 'fotografia2')
                    .replaceAll('fotografia 2 fonte', 'fotografia2fonte')
                    .replaceAll('Link foto 1', 'Linkfoto1')
                    .replaceAll('Link foto 2', 'Linkfoto2')
                    .replaceAll('Link áudio', 'Linkaudio')
                    .replaceAll('Link ilustração', 'Linkilustracao')
                    .replaceAll('video execução', 'videoexecucao')
                    .replaceAll('video fonte', 'videofonte')
                    .replaceAll('Link vídeo ', 'Linkvideo');
                var jsonObject = JSON.parse(stringified);
                // //console.log(jsonObject);
                jsonObject.map(d => (

                    this.SalvarInstrumentos(d)
                )
                );

            })


        }

        return (
            <div className="page-wrapper" >
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Importação de Instrumentos" ></Header>
                <section className="course-one course-one__teacher-details home-one pt-1 ">
                    <Container >

                        <div className="container p-2 ">
                            {this.state.Aguardando
                                ?
                                <div className="row">
                                    <div className="col-12 border p-2">
                                        <input type="file" onChange={(e) => { const file = e.target.files[0]; readExcel(file); }}></input>
                                    </div>
                                </div>
                                : <></>
                            }
                            <div className="row">
                                <div className="col-12 progress" style={{ height: 5 }}>
                                    <div className="progress-bar" role="progressbar" style={{ width: (100 - ((this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror) * 100 / this.state.countgeneral)).toString().concat('%') }} aria-valuenow={((this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror) * 100 / this.state.countgeneral).toString()} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-12 border">
                                    {/* <>{(100 - ((this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror) * 100) / this.state.countgeneral).toString()}</><br></br> */}
                                    <>{this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror === 0 && this.state.Aguardando === false ? "Processamento concluído" : "Aguarde.." || (100 - ((this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror) * 100 / this.state.countgeneral)).toString()}</><br></br>
                                    Registros lidos: {this.state.countgeneral}- {this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror}<br></br>
                                    Registros pulados: {this.state.countskip}<br></br>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-6 border">

                                    Registros Processado: {this.state.count}<br></br>
                                    <b>Itens Processados</b>
                                    <table>
                                        <tbody>
                                            {
                                                this.state.items.map(d => (
                                                    <tr className="col-12" key={d.Idioma + d.NomeOriginal}>
                                                        <td>{d.NomeOriginal}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6 border">
                                    Registros com Erros: {this.state.counterror}<br></br>
                                    <b>Itens com erro</b>
                                    <table>
                                        {
                                            this.state.itemserror.map(d => (
                                                <tr className="col-12" key={d.Idioma + d.NomeOriginal}>
                                                    <td>{d.NomeOriginal}</td>
                                                </tr>
                                            ))
                                        }
                                    </table>
                                </div>
                            </div>
                            <div className="row">

                            </div>
                        </div>
                    </Container>
                </section>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
