import http from "../utils/apiAvancada";

class GlossarioDataService {
  getAll() {
    return http.get("/glossarios/admin");
  }

  get(id) {
    return http.get(`/glossarios/${id}`);
  }

  create(data) {
    return http.post("/glossarios", data);
  }

  update(id, data) {
    return http.put(`/glossarios/${id}`, data);
  }

  delete(id) {
    return http.delete(`/glossarios/${id}`);
  }

  deleteAll() {
    return http.delete(`/glossarios`);
  }

  findByTitle(title) {
    return http.get(`/glossarios?title=${title}`);
  }
}

export default new GlossarioDataService();