import http from "../utils/apiAvancada";
// import { CheckStatusCode } from './../utils/util';

class InstrumentoDataService {
  getAll() {
    return http.get("/instrumentos");
  }

  get(id) {
    return http.get(`/instrumentos/${id}`)
  }
  //   return http.get(`/instrumentos/${id}`, {

  //     // validateStatus: function (status) {
  //     //   CheckStatusCode(status);
  //     //   return status < 500; // Resolve only if the status code is less than 500
  //     // }
  //   })
  //     .catch(function (error) {
  //       if (error.response) {
  //         // The request was made and the server responded with a status code
  //         // that falls out of the range of 2xx
  //         //console.log(error.response.data);
  //         //console.log(error.response.status);
  //         //console.log(error.response.headers);
  //       } else if (error.request) {
  //         // The request was made but no response was received
  //         // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //         // http.ClientRequest in node.js
  //         //console.log(error.request);
  //       } else {
  //         // Something happened in setting up the request that triggered an Error
  //         //console.log('Error', error.message);
  //       }
  //       //console.log(error.config);
  //     });


  // }

  create(data) {

    return http.post("/instrumentos", data);
  }

  update(id, data) {
    return http.put(`/instrumentos/${id}`, data);
  }

  delete(id) {
    return http.delete(`/instrumentos/${id}`);
  }

  deleteAll() {
    return http.delete(`/instrumentos`);
  }

  findByTitle(title, lang) {
    return http.get(`/instrumentos?nome=${title}&lang=${lang}`);
  }
  findByTitleAndLang(title, lang) {
    return http.get(`/instrumentos/lang?nome=${title}&lang=${lang}`);
  }
}

export default new InstrumentoDataService();