import http from "../utils/apiAvancada";

class PoliticaDataService {
  getAll() {
    return http.get("/Politica");
  }
  getAllAdmin() {
    return http.get("/Politica/Admin");
  }

  get(id) {
    return http.get(`/Politica/${id}`);
  }

  create(data) {
    return http.post("/Politica", data);
  }

  update(id, data) {
    return http.put(`/Politica/${id}`, data);
  }

  delete(id) {
    return http.delete(`/Politica/${id}`);
  }

  deleteAll() {
    return http.delete(`/Politica`);
  }

  findByTitle(title) {
    return http.get(`/Politica?title=${title}`);
  }
}

export default new PoliticaDataService();