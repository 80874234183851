import React, { Component } from 'react';
// import PlanoDataService from "../services/plano.service";
import InstrumentoDataService from "../services/instrumento.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';

const columns = [
  { name: '#',   selector: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>{ row.id}</small></Button>}</>, right: true },
  // { name: '#',  selector: row =>  row[ 'id'], sortable: true, },
  { name: 'Idioma', selector: row =>  row[ 'Idioma'], sortable: true, wrap: true, compact: true, },
  { name: 'Nome Original', selector: row =>  row[ 'NomeOriginal'], sortable: true, compact: false, },
  { name: 'Nome Dicionário', selector: row =>  row[ 'NomeDicionario'], sortable: true, compact: false, },
  { name: 'Sinonimos', selector: row =>  row[ 'Sinonimos'], sortable: true, compact: true, wrap: true,},
  // { name: 'Classificação Geral', selector: row =>  row[ 'ClassificacaoGeral'], sortable: true, compact: true, wrap: true, },

  { name: 'Linkfoto1', selector:        row =>  row['Linkfoto1'],         sortable: true, compact: true, wrap: true,   cell: row => <> {row.Linkfoto1        !== null && row.Linkfoto1.toLowerCase().includes('http') ? 'X' : '-'}</> },
  { name: 'fotografia1fonte', selector: row =>  row['fotografia1fonte'],  sortable: true, compact: true, wrap: true,   cell: row => <> {row.fotografia1fonte !== null && row.fotografia1fonte.toLowerCase().includes('http') ? 'X' : '-'}</> },
  { name: 'fotografia1', selector:      row =>  row['fotografia1'],       sortable: true, compact: true, wrap: true,   cell: row => <> {row.fotografia1      !== null && row.fotografia1.toLowerCase().includes('http') ? 'X' : '-'}</> },
  { name: 'Linkaudio', selector:        row =>  row['Linkaudio'],         sortable: true, compact: true, wrap: true,   cell: row => <> {row.Linkaudio        !== null && row.Linkaudio.toLowerCase().includes('http') ? 'X' : '-'}</> },
  { name: 'audiofonte', selector:       row =>  row['audiofonte'],        sortable: true, compact: true, wrap: true,   cell: row => <> {row.audiofonte       !== null && row.audiofonte.toLowerCase().includes('http') ? 'X' : '-'}</> },
  { name: 'audioexecucao', selector:    row =>  row['audioexecucao'],     sortable: true, compact: true, wrap: true,   cell: row => <> {row.audioexecucao    !== null && row.audioexecucao.toLowerCase().includes('http') ? 'X' : '-'}</> },
  { name: 'videofonte', selector:       row =>  row['videofonte'],        sortable: true, compact: true, wrap: true,   cell: row => <> {row.videofonte       !== null && row.videofonte.toLowerCase().includes('http') ? 'X' : '-'}</> },
  { name: 'videoexecucao', selector:    row =>  row['videoexecucao'],     sortable: true, compact: true, wrap: true,   cell: row => <> {row.videoexecucao    !== null && row.videoexecucao.toLowerCase().includes('http') ? 'X' : '-'}</> },

  { name: 'Amostra', selector:          row =>  row[ 'sample'],           sortable: true, right: true, omit: false,  cell: row => <> {row.sample === 1 ? 'X' : '-'}</> },
  { name: 'Publicado', selector:        row =>  row[ 'published'],        sortable: true, right: true, omit: false,  cell: row => <> {row.published === 1 ? 'X' : '-'}</> },

]
const handleButtonClick = (state) => {
  window.location = "/editar-instrumento/" + state.id;
};

class Instrumentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Instrumentos: [],
      message: ""
    };

  }

  handleLogout = () => {

  }

  componentDidMount() {
    this.getInstrumentos();
  }

  getInstrumentos() {
    InstrumentoDataService.getAll()
      .then(response => {
        this.setState({
          Instrumentos: response.data
        });
      })
      .catch(e => {
        //console.log(e);
      });
  }


  render() {


    return (

      <div className="page-wrapper" >
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Instrumentos" ></Header>
        <section className="course-one course-one__teacher-details home-one">

          <Container fluid={false}>
            {this.state.Instrumentos !== undefined && this.state.Instrumentos.length > 0
              ?
              <>
                <Container fluid={true}>
                  <Row className={"pull-right"}>
                    <Col className={"py-2 "}>
                      <Link to="/add-motorista" className="btn pull-right btn-info botao-acao" >+</Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                       <DataTableExtensions exportHeaders={true}
                        columns={columns}
                        data={this.state.Instrumentos}>
                        <DataTable
                          defaultSortField="nomeOriginal"
                          defaultSortAsc={false}
                          pagination
                          highlightOnHover
                        />
                      </DataTableExtensions>
                    </Col>
                  </Row>
                </Container>
              </>
              :
              <p>Carregando Instrumentos</p>
            }
          </Container>{/*<!-- /.container -->*/}

        </section>

        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default Instrumentos;