import http from "../utils/apiAvancada";

class AssinaturaDataService {
  getAll() {
    return http.get("/assinaturas/published");
  }

  get(id) {
    return http.get(`/assinaturas/${id}`);
  }

  create(data) {
    return http.post("/assinaturas", data);
  }

  update(id, data) {
    return http.put(`/assinaturas/${id}`, data);
  }

  delete(id) {
    return http.delete(`/assinaturas/${id}`);
  }

  deleteAll() {
    return http.delete(`/assinaturas`);
  }

  findByTitle(title) {
    return http.get(`/assinaturas?title=${title}`);
  }
}

export default new AssinaturaDataService();