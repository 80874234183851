
 import React from 'react'
// import Feedback from './Feedback';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Feedback from '../components/Feedback';
// import FeedbackComImagem from './FeedbackComImagem';

const TOKEN_KEY = 'Token';


export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}
export const isProfileChosen = () => {
    return (parseInt(localStorage.getItem('IdEmpresa')) > 0);
}
export const getToken = () => {

    return localStorage.getItem(TOKEN_KEY);
}
export const API_URL = () => {
    return (process.env.NODE_ENV === 'development') ? "https://localhost:6001/api" : "https://revviapi.azurewebsites.net/Api/";
}

export const getId = () => {
    return localStorage.getItem('Id');
}

export const getIdEmpresa = () => {
    return localStorage.getItem('IdEmpresa');
}

export const getIdParceiro = () => {
    return localStorage.getItem('IdParceiro');
}


export const formSerialize = (formElement, stringify = false) => {

    const values = {};
    const inputs = formElement.elements;
    // //console.log(formElement);
    for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].name !== "") {
            values[inputs[i].name] = inputs[i].value;
        }
    }
    if (stringify)
        return JSON.stringify(values)
    else
        return values;
}

export const serialize = (form, stringify = true) => {
    let requestArray = [];
    form.querySelectorAll('[name]').forEach((elem) => {
        //console.log("<" + elem.name + ">");
        var jsonArg1 = [];
        jsonArg1.name = elem.name;
        jsonArg1.value = elem.value;
        if (elem.name !== '') {
            requestArray.push(jsonArg1);
        }
        // if ((elem.name !== "") && (elem !== undefined)&& elem.name !=="undefined") {
        //     requestArray.push(elem.name + '=' + elem.value);
        // }
    });
    if (requestArray.length > 0)

        if (stringify)
            return JSON.stringify(requestArray)
        else
            return requestArray;

    else
        return false;
}

export const showMessageErro = (
    mensagem,
    titulo = "Ocorre um erro",
    detalhe = "Por favor, tente novamente.",
    backPage = false ) => {
    const options = {
        //onOpen: props => console.log('Abrindo'),
        onClose: props => backPage ? window.history.back() : "",
        onCloseClick: props => backPage ? window.history.back() : "",
        // autoClose: 6000,
        // closeButton: FontAwesomeCloseButton,
        //  type: toast.TYPE.INFO,
        // hideProgressBar: false,
        position: toast.POSITION.BOTTOM_CENTER,
        // pauseOnHover: true,
        // transition: MyCustomTransition,
        // progress: 0.2
        // and so on ...
    };
    toast(<Feedback titulo={titulo} mensagem={mensagem + '\n' + detalhe} linkEntendi={true} />, options);
}
export const showMessage = (titulo, mensagem, linkEntendi = false, handleLink = null, textoLink = "", onCloseClick = null) => {
    const options = {
        onOpen: props => console.log('Abrindo'),
        onClose: props => window.location = onCloseClick,
        onCloseClick: props => window.location = onCloseClick,
        //autoClose: 6000,
        // closeButton: FontAwesomeCloseButton,
        // type: toast.TYPE.INFO,
        // hideProgressBar: false,
        // position: toast.POSITION.TOP_LEFT,
        // pauseOnHover: true,
        // transition: MyCustomTransition,
        // progress: 0.2
        // and so on ...
    };

    toast(<Feedback titulo={titulo}
        mensagem={mensagem}
        linkEntendi={linkEntendi}
        textoLink={textoLink}
        handleLink={handleLink}

    />, options
    );
}
export const showMessageInfo = (titulo, mensagem, linkEntendi = false, handleLink = null, textoLink = "", onCloseClick = null) => {
    const options = {
        onOpen: props => console.log('Abrindo'),
        onClose: props => window.location = onCloseClick,
        onCloseClick: props => window.location = onCloseClick,
        // autoClose: 6000,
        // closeButton: FontAwesomeCloseButton,
        //  type: toast.TYPE.INFO,
        // hideProgressBar: false,
        position: toast.POSITION.BOTTOM_CENTER,
        // pauseOnHover: true,
        // transition: MyCustomTransition,
        // progress: 0.2
        // and so on ...
    };

    toast(<Feedback titulo={titulo}
        mensagem={mensagem}
        linkEntendi={linkEntendi}
        textoLink={textoLink}
        handleLink={handleLink}
    //onCloseClick={onCloseClick}
    />, options
    );
}

export const getEmail = () => { return localStorage.getItem('Email'); }
// export const getToken = () => { return localStorage.getItem('Token'); }
// export const isLogin = () => { CheckCorrectRole(); if (localStorage.getItem('Token')) { return true; } return false; }
// const CheckCorrectRole = () => {
//     //console.log(localStorage.getItem('Role'));
//     if ((localStorage.getItem('Role') !== 'ROLE_ADMIN') && localStorage.getItem('Role') !== null) {
//         logout();
//     }
// }
export const CheckStatusCode = (status) => {
    //console.log(status);

}
export const logout = () => {
    localStorage.removeItem('Id');
    localStorage.removeItem('Name');
    localStorage.removeItem('Email');
    localStorage.removeItem('Role');
    localStorage.removeItem('Token');
    localStorage.removeItem('IdVeiculo');
    localStorage.removeItem('IdMotorista');
    localStorage.removeItem('PularOnBoarding');
    console.log('logout');
    window.location.reload(true);
}

// //toaster com um botão link, para usar link passe true no linkEntendi, se não passar texto no textoLink vai ficar escrito entendi.
// export const showMessage = (titulo, mensagem, linkEntendi = false, handleLink = null, textoLink = "") => {
//     toast(<Feedback titulo={titulo}
//         mensagem={mensagem}
//         linkEntendi={linkEntendi}
//         textoLink={textoLink}
//         handleLink={handleLink}
//     />
//     );
// }
// //opção com link e botão, para aparecer o botão passe o texto do botão e o handler
// export const showMessageBotao = (titulo, mensagem, linkEntendi = false, handleLink = null, textoLink = "", 
//                             textoBotao = "", handleBotao = null) => {
//     toast(<Feedback titulo={titulo}
//         mensagem={mensagem}
//         linkEntendi={linkEntendi}
//         textoLink={textoLink}
//         handleLink={handleLink}
//         textoBotao={textoBotao}
//         handleBotao={handleBotao}
//     />
//     );
// }
// //opção com dois botões
// export const showMessagePergunta = (titulo, mensagem, textoBotaoUm = "", handleBotaoUm = null, 
//                             textoBotaoDois = "", handleBotaoDois = null) => {
//     toast(<Feedback titulo={titulo}
//         mensagem={mensagem}
//         textoBotaoUm={textoBotaoUm}
//         handleBotaoUm={handleBotaoUm}
//         textoBotaoDois={textoBotaoDois}
//         handleBotaoDois={handleBotaoDois}
//     />
//     );
// }
// //opção que aparece imagem na parte de cima, antes dos textos e botões
// export const showMessageImagem = (titulo, mensagem, imagem = "", linkEntendi = false,  textoLink = "",  
//                                   handleLink = null, textoBotao = "", handleBotao = null) => {

//     toast.success(<FeedbackComImagem titulo={titulo}
//         mensagem={mensagem}
//         linkEntendi={linkEntendi}
//         textoLink={textoLink}
//         imagem={imagem}
//         handleLink={handleLink}
//         textoBotao={textoBotao}
//         handleBotao={handleBotao}
//     />
//     );
// }


// export const showMessageErro = (mensagem) => {
//     toast(<Feedback titulo={"Ocorre um erro"} mensagem={mensagem + " Por favor, tente novamente."} linkEntendi={true} />);
// }

