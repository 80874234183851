import http from "../utils/apiAvancada";

class PlanoDataService {
  getAll() {
    return http.get("/sinonimos");
  }

  get(id) {
    return http.get(`/sinonimos/${id}`);
  }

  create(data) {
    return http.post("/sinonimos", data);
  }

  update(id, data) {
    return http.put(`/sinonimos/${id}`, data);
  }

  delete(id) {
    return http.delete(`/sinonimos/${id}`);
  }

  deleteAll() {
    return http.delete(`/sinonimos`);
  }

  findByTitle(title) {
    return http.get(`/sinonimos?title=${title}`);
  }
}

export default new PlanoDataService();