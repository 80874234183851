import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import CodigoDataService from "../services/codigo.service";

import Header from './Common/Header';
import NavTop from './Common/NavTop';
import NavMain from './Common/NavMain';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import TextInput from './Common/TextInput';
import Botao from './Common/Botao';
// import BannerHome from './BannerHome';
// import BannerPlanos from './BannerPlanos';
// import MailChimp from './mailchimp';
// import SejaBemVindo from './SejaBemVindo';
// import Fase01 from './Fase01';
// import Planos from './Planos';
export default class GenerateCode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            Aguardando: true,
            itemserror: [],
            count: 0,
            counterror: 0,
            countgeneral: 0,
            countskip: 0,
            message: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        let cadastro =
        {
            qtde: event.target.qtde.value,
            meses: event.target.meses.value,
            obs: event.target.obs.value

        };
        this.setState({ message: [] })
        let messages = []



        CodigoDataService.create(cadastro).then(res => {
            if (res.status === 200) {
                console.log(res.data);

                window.location = "/codigos";
            }
        }).catch(function (error) {

            let message = 'Ocorreu um erro inesperado.';
            if (error.response !== undefined) {
                message = error.response.data.message;
            }
            else
                if (error.message !== undefined) {
                    message = error.message;
                }


            messages.push({ 'id': 4, 'value': message })
            // this.setState({ message: message });
        });

    }

    render() {



        return (
            <div className="page-wrapper" >
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Geração de códigos." ></Header>
                <section className="course-one course-one__teacher-details home-one pt-1 ">

                    <form onSubmit={this.handleSubmit}>

                        <Container >
                            <Row>
                                <Col></Col>
                                <Col xs={10}>
                                    <div className="block-title mb-0 font-size-24 lh-38 ">
                                        <h2 className="block-dtitle__titsle font-size-30">
                                            Informe a quantidade de códigos que você deseja gerar.<br></br></h2>{/*<!-- /.block-title__title -->*/}
                                    </div>{/*<!-- /.block-title -->*/}
                                </Col>
                                <Col></Col>
                            </Row>

                        </Container>
                        <Container fluid={false} className="mt-2 pt-5 pb-5  ">
                            <Row>
                                <Col xs={2}>
                                    <TextInput
                                        id="qtde"
                                        name="qtde"
                                        type="text"
                                        min={1}
                                        // max={parseInt(format(new Date(), 'yyyy'))}
                                        placeholder=""
                                        required="required"
                                        className="form-control py-4"
                                        // validate="anoFabricacao"
                                        labelValue="Quantidade "
                                        // maxLength="4"
                                        // defaultValue={this.state.AnoFabricacao}
                                        // onChange={this.OnChangeAnoFabricacao}
                                        formGroupClass="px-0"
                                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                    />
                                </Col>

                                <Col xs={2}>
                                    <TextInput
                                        id="meses"
                                        name="meses"
                                        type="text"
                                        min={1}
                                        // max={parseInt(format(new Date(), 'yyyy'))}
                                        placeholder=""
                                        required="required"
                                        className="form-control py-4"
                                        // validate="anoFabricacao"
                                        labelValue="Meses"
                                        // maxLength="4"
                                        // defaultValue={this.state.AnoFabricacao}
                                        // onChange={this.OnChangeAnoFabricacao}
                                        formGroupClass="px-0"
                                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <TextInput
                                        id="obs"
                                        name="obs"
                                        type="text"
                                        min={1}
                                        // max={parseInt(format(new Date(), 'yyyy'))}
                                        placeholder=""
                                        required="required"
                                        className="form-control py-4"
                                        // validate="anoFabricacao"
                                        labelValue="Observação"
                                        // maxLength="4"
                                        // defaultValue={this.state.AnoFabricacao}
                                        // onChange={this.OnChangeAnoFabricacao}
                                        formGroupClass="px-0"
                                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    <Botao className={"course-one__link font-size-20 lh-21"} value="Gerar" />
                                </Col>
                            </Row>
                        </Container>
                    </form>
                </section>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
