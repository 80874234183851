import React, { Component } from 'react';
import AssinaturaDataService from "../services/assinatura.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';

const columns = [
  { name: '#', selector: 'id', sortable: true, },
  { name: 'Usuário', selector: 'usuarioId', sortable: true,  cell: row => <>{row.user === null ? 'Usuário inválido' : `${row.user.nome} (${row.user.email})` } </>, },
  { name: 'Plano', selector: 'planoId', sortable: true, wrap: true, compact: true,  cell: row => <>{row.plano === null ? 'Sem plano ou com Código ' : row.plano.titulo} </>,},
  // { name: 'Nota', selector: 'nota', sortable: true, right: true, },
  { name: 'Assinado em', selector: 'createdAt', sortable: true, compact: true, },
  { name: 'Valido até', selector: 'validUntil', sortable: true, right: true, omit: false, compact: true},
  { name: 'Ações', compact: true, cell: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>Edit</small></Button>}</>, right: true },
]
const handleButtonClick = (state) => {
  window.location = "/editar-motorista/" + state.id;
};

class Assinaturas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assinaturas: [],
      Mensagem: "Carregando assinaturas"
    };

  }

  handleLogout = () => {

  }

  componentDidMount() {
    this.getAssinaturas();
  }

  getAssinaturas() {
    AssinaturaDataService.getAll()
      .then(response => {
          console.log();
        this.setState({
          assinaturas: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
      })
      .catch(e => {
        //console.log(e);
      });
  }


  render() {


    return (

      <div className="page-wrapper" >
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Assinaturas" ></Header>
        <section className="course-one course-one__teacher-details home-one">
          <Container>
            {this.state.assinaturas.length > 0
              ?
              <>
                <Container fluid={true}>
                  <Row className={"pull-right"}>
                    <Col className={"py-2 "}>
                      <Link to="/add-motorista" className="btn pull-right btn-info botao-acao" >+</Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DataTableExtensions exportHeaders={true}
                        columns={columns}
                        data={this.state.assinaturas}>
                        <DataTable
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination
                          highlightOnHover
                        />
                      </DataTableExtensions>
                    </Col>
                  </Row>
                </Container>
              </>
              :
              <p>{this.state.Mensagem}</p>
            }
          </Container>{/*<!-- /.container -->*/}
        </section>

        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default Assinaturas;