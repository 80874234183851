import http from "../utils/apiAvancada";

class TermoDataService {
  getAll() {
    return http.get("/termo");
  }
  getAllAdmin() {
    return http.get("/termo/admin");
  }

  get(id) {
    return http.get(`/termo/${id}`);
  }

  create(data) {
    return http.post("/termo", data);
  }

  update(id, data) {
    return http.put(`/termo/${id}`, data);
  }

  delete(id) {
    return http.delete(`/termo/${id}`);
  }

  deleteAll() {
    return http.delete(`/termo`);
  }

  findByTitle(title) {
    return http.get(`/termo?title=${title}`);
  }
}

export default new TermoDataService();