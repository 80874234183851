import http from "../utils/apiAvancada";

class AssinaturaDataService {
    getAll() {
        return http.get("/auth/newsletters");
    }
    registerNewsletter(data) {
        return http.post("/auth/registerNewsletter", data);
    }
    unregisterNewsletter(data) {
        return http.post("/auth/unregisterNewsletter", data);
    }
}

export default new AssinaturaDataService();