import React, { Component } from 'react';
// import { Col, Row } from 'react-bootstrap';
// import { Link, NavLink } from 'react-router-dom';
import { Link, } from 'react-router-dom';

class FooterMenu extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {

    }

    componentDidMount() {


    }

    render() {
        return (

            <footer className="site-footer">

                <div className="site-footer__bottom">
                    <div className="container">
                        <p className="site-footer__copy font-size-14 lh-14">&copy; Copyright 2021 by <Link to="#">Admin - Cântaro Arte&Edução</Link>&nbsp;-&nbsp;<small > Version - {global.appVersion} | Painel administrativo</small></p>
                        <div className="site-footer__social">
                            <Link to="#" data-target="html" className="scroll-to-target site-footer__scroll-top"><i className="kipso-icon-top-arrow"></i></Link>
                            <Link to="#"><i className="fab fa-twitter"></i></Link>
                            <Link to="#"><i className="fab fa-facebook-square"></i></Link>
                            <Link to="#"><i className="fab fa-pinterest-p"></i></Link>
                            <Link to="#"><i className="fab fa-instagram"></i></Link>
                        </div>{/*<!-- /.site-footer__social */}
                        {/*<!-- /.site-footer__copy */}
                    </div>{/*<!-- /.container */}
                </div>{/*<!-- /.site-footer__bottom */}
            </footer>


        );
    }
}
export default FooterMenu;