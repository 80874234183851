
import React, { Component/*, NavLink*/ } from 'react'
// import Botao from '../components/inputs/Botao';
import { Row, Col } from 'react-bootstrap';
import Botao from './Common/Botao';
class Feedback extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fecharDiv: false
        }
    }


    render() {
        //let valor = "";
        return (
            <div className="feedback">

                <h3 className="feedback-titulo"><b>{this.props.titulo}</b></h3>
                <br />
                <p className="feedback-texto">{this.props.mensagem}</p>

                {this.props.linkEntendi || this.props.textoLink !== "" ?
                    <div className="row">
                        <div className="col-sm-12">
                            <Botao className="btn botao-feedback"
                                value={this.props.textoLink !== "" ? this.props.textoLink : "Entendi"}
                                BotaoLink="true"
                                FunctionClickCall={this.props.handleLink}></Botao>
                        </div>
                    </div>
                    :
                    ""
                }
                {this.props.handleBotao !== undefined && this.props.textoBotao !== "" ?
                    <div className="row">
                        <div className="col-sm-12">
                            <Botao className="btn btn-primary mr-2 col-12 mt-4"
                                value={this.props.textoBotao}
                                BotaoLink="true"
                                FunctionClickCall={this.props.handleBotao}></Botao>
                        </div>
                    </div>
                    :
                    ""
                }
                {this.props.handleBotaoUm !== undefined && this.props.textoBotaoUm !== "" &&
                    this.props.handleBotaoDois !== undefined && this.props.textoBotaoDois !== "" ?
                    <Row>
                        <Col xs={6}>
                            <Botao className="btn btn-danger"
                                value={this.props.textoBotaoUm}
                                BotaoLink="true"
                                FunctionClickCall={this.props.handleBotaoUm}></Botao>
                        </Col>
                        <Col xs={6}>

                            <Botao className="btn btn-success "
                                value={this.props.textoBotaoDois}
                                BotaoLink="true"
                                FunctionClickCall={this.props.handleBotaoDois}></Botao>
                        </Col>
                    </Row>
                    :
                    ""
                }
            </div>
        );
    }
}

export default Feedback;