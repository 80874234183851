import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import Header from './Common/Header';
import NavTop from './Common/NavTop';
import NavMain from './Common/NavMain';
import Footer from './Common/Footer';
import { TextInput } from './Common/TextInput';
import FooterMenu from './Common/FooterMenu';
import PoliticaDataService from "../services/politica.service";
import { Link } from 'react-router-dom';
import {  Col, Container, Row } from 'react-bootstrap';
// import CheckBox from './Common/CheckBox';
// import Toogles from './Common/Toogles';
// import Switch from './Common/Switch';
// import { showMessage, showMessageInfo } from '../utils';


class EditarPolitica extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Titulo: "",
            Texto: "",
            Ordem: 0,
            Idioma: ""
        };
        this.handleTextoChange  = this.handleTextoChange.bind(this);
        this.handleDataChange   = this.handleDataChange.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
        this.onClickPublished   = this.onClickPublished.bind(this);
        this.onClickSample      = this.onClickSample.bind(this);
        this.closeHandle        = this.closeHandle.bind(this);
        this.entendiHandle      = this.entendiHandle.bind(this);
    }
    handleDataChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    onClickPublished = (event) => {
        if (this.state.published === 1) {
            this.setState({
                published: 0
            });
        } else {
            this.setState({
                published: 1
            });
        }
    }


    onClickSample = (event) => {
        if (this.state.sample === 1) {
            this.setState({
                sample: 0
            });
        } else {
            this.setState({
                sample: 1
            });
        }
    }
    entendiHandle = e => {
        e.preventDefault();
        window.location = './home';
        console.log('close button clicked')
    }

    closeHandle = e => {
        e.preventDefault();
        console.log('close button clicked')
    }
    async handleSubmit(event) {
        event.preventDefault();

        let data = JSON.stringify(this.state);
        console.log(data);

        PoliticaDataService.update(this.state.id, data)
            .then(response => {
                alert(response.data.message);
                window.location = './politica';
                // showMessage('', response.data.message, true, this.entendiHandle, 'Entendi', this.closeHandle)

            })
            .catch(e => {
                console.log(e);
                // this.setState({ itemserror: this.state.itemserror.concat(data) })
                // this.setState({ counterror: this.state.counterror + 1 });
            });

    };

    handleLogout = () => {

    }

    handleTextoChange(event, editor) {
        this.setState({ texto: editor.getData() });
    }
    handleTituloChange(event, editor) {
        this.setState({ titulo: editor.getData() });
    }
    componentDidMount() {
        this.getPolitica();
        // //console.log(this.state.instrum);
    }



    getPolitica() {
        let politica = (this.props.match.params.politica !== undefined ? this.props.match.params.politica : '-');
        this.setState({ letra: politica.toUpperCase() })
        PoliticaDataService.get(politica)
            .then(response => {
                //console.log('carregarPolitica')
                //console.log(response.data)
                //console.log(response.data.length)

                if (response.data) {
                    this.setState({
                        instrum: response.data,

                        id: response.data.id,
                        texto: response.data.texto,
                        titulo: response.data.titulo,
                        Idioma: response.data.Idioma,
                        ordem: response.data.ordem
                    });

                }
                else {
                    this.setState({ Error: true });
                }
            })
            .catch(e => {
                //console.log(e);
            });
    }

    render() {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Editando politica" ></Header>
                <Container className='pb-5'>
                    <form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput type="hidden" labelValue={"id"} id={"id"} name={"id"} defaultValue={this.state.id} value={this.state.id} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="form-group">

                                <TextInput onChange={this.handleDataChange} labelValue={"titulo"} id={"titulo"} name={"titulo"} data-key="titulo" type="text" value={this.state.titulo}  className="form-control" />
                            </Col>
                        </Row>


                        <Row>
                            <Col sm={12} className="form-group">
                                <TextInput onChange={this.handleTextoChange} labelValue={"Texto"} id={"Texto"} name={"Texto"} type="ckeditor" value={this.state.texto} className="form-control" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2} className="form-group">
                                <TextInput onChange={this.handleDataChange} labelValue={"Idioma"} id={"Idioma"} name={"Idioma"} defaultValue={this.state.Idioma} value={this.state.Idioma} className="form-control" />
                            </Col>
                        </Row>
                        <Row className="row">
                            <Col className="form-group col-md-4">
                                <div className="">
                                    <button className="btn btn-primary mr-2" type="submit">Salvar politica</button>
                                    <Link to="/politica" className="btn btn-warning" type="button">Voltar</Link>
                                </div>
                            </Col>
                            <Col className="form-group col-md-4">
                                <div className="">

                                </div>
                            </Col>
                        </Row>


                    </form>
                </Container>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
export default EditarPolitica;