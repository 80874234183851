import React, { Component } from 'react';
import NewslettersDataService from "../services/newsletters.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Col, Row, Container } from 'react-bootstrap';

// import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';

const columns = [
  { name: '#', selector: 'id', sortable: true, width: '5%' },
  { name: 'E-Mail', selector: 'email', sortable: true, wrap: true, compact: true, },
  { name: 'Data Criação', selector: 'createdAt', sortable: true, compact: true, },
  { name: 'Data Atualização', selector: 'updatedAt', sortable: true, compact: true, },
  { name: 'Ativo', selector: 'ativo', sortable: true, right: true, omit: false, compact: true, cell: row => <> {row.ativo === 1 ? 'SIM' : 'NÃO'}</> }
  //   { name: 'Data Criação', selector: 'createdAt', sortable: true, right: true, omit: false, compact: true, cell: row => <> {row.ativo === true ? 'X' : ''}</> },
  //   { name: 'Ações', compact: true, cell: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>Edit</small></Button>}</>, right: true },
]
// const handleButtonClick = (state) => {
//   window.location = "/editar-motorista/" + state.id;
// };

class Newsletters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigos: [],
      Mensagem: "Carregando newsletters"
    };

  }

  handleLogout = () => {

  }

  componentDidMount() {
    this.getNewsletters();
  }

  getNewsletters() {
    NewslettersDataService.getAll()
      .then(response => {
        console.log();
        this.setState({
          codigos: response.data,
          Mensagem: 'Nenhum registro para exibir'
        });
      })
      .catch(e => {
        //console.log(e);
      });
  }


  render() {


    return (

      <div className="page-wrapper" >
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Newsletters" ></Header>
        <section className="course-one course-one__teacher-details home-one">
          <Container fluid={false}>
            {this.state.codigos !== undefined && this.state.codigos.length > 0
              ?
              <>
                <Container fluid={true}>
                  <Row className={"pull-right"}>
                    <Col className={"py-2 "}>
                      {/* <Link to="/generate-code" className="btn pull-right btn-info botao-acao" >Gerar códigos</Link> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DataTableExtensions exportHeaders={true}
                        columns={columns}
                        data={this.state.codigos}>
                        <DataTable
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination
                          highlightOnHover
                        />
                      </DataTableExtensions>
                    </Col>
                  </Row>
                </Container>
              </>
              :
              <>
                {/* <Link to="/generate-code" className="btn pull-right btn-info botao-acao" >Gerar códigos</Link> */}
                <p>{this.state.Mensagem}</p>
              </>
            }
          </Container>{/*<!-- /.container -->*/}
        </section>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default Newsletters;