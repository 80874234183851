import axios from "axios";
import { logout } from "../utils";
var p_baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:8081/api' : 'https://api.dicionariodeinstrumentos.com/api';
p_baseURL =  'https://api.dicionariodeinstrumentos.com/api';
var clientToken = localStorage.getItem('Token');

const instance = axios.create({
  baseURL: p_baseURL,
  timeout: 0,
  headers: {
    "x-access-token": clientToken,
    "Content-type": "application/json"
  },
  params: {
    'client_id': process.env.CLIENTID,
    'client_secret': process.env.CLIENTSECRET
  }
}

);

instance.interceptors.response.use(response => {
  return response;
}, error => {
  console.log(error);
  if (error.response.status === 400) {
    return Promise.reject(error);
  }
  else if (error.response.status === 500) {
    return Promise.reject(error);
  }
  else if (error.response.status === 401) {

    if ((error.response.config.url === '/auth/signin') || (error.response.config.url === '/auth/changePasswordLost') || (error.response.config.url === '/auth/changePassword'))
      return Promise.reject(error);
    else
      logout();
  }
  else if (error.response.status === 403) {
    logout();
  }
  else if (error.response) {
    if (error.response.data.message.name === 'TokenExpiredError') {
      //console.log('LOGOUT')
      logout();
    }

  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    //console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    //console.log('Error', error.message);
  }
  return error;
});

instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  //console.log('789');
  return config;
}, function (error) {
  // Do something with request error
  //console.log('000');
  return Promise.reject(error);
});

export default instance;