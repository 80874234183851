import http from "../utils/apiAvancada";

class CodigoDataService {
  getAll() {
    return http.get("/codigos");
  }

  get(id) {
    return http.get(`/codigos/${id}`);
  }

  create(data) {
    return http.post("/codigos", data);
  }

  update(id, data) {
    return http.put(`/codigos/${id}`, data);
  }

  delete(id) {
    return http.delete(`/codigos/${id}`);
  }

  deleteAll() {
    return http.delete(`/codigos`);
  }

  findByTitle(title) {
    return http.get(`/codigos?title=${title}`);
  }
}

export default new CodigoDataService();