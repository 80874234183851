import React, { Component } from 'react';
// import PlanoDataService from "../services/plano.service";
import UsuarioDataService from "../services/usuario.service";
// import { cpf } from 'cpf-cnpj-validator';
// import { format } from 'date-fns'
// import { ptBR } from 'date-fns/locale'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Col, Row, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
import NavTop from './Common/NavTop';
import Header from './Common/Header';
import NavMain from './Common/NavMain';

const columns = [
  // `id`, ``, ``, `nome`, `escola`, `dataNascimento`, `password`, `plano`, `validoAte`, `createdAt`, `updatedAt`
  { name: '#', selector: 'id', sortable: true, },
  { name: 'E-Mail', selector: 'email', sortable: true, },
  { name: 'Nome', selector: 'nome', sortable: true, wrap: true, compact: true, },
  { name: 'Nascimento', selector: 'dataNascimento', sortable: true, wrap: true, compact: true, },

  { name: 'Plano', selector: 'plano', sortable: true, wrap: true, compact: true, },
  { name: 'Válido até', selector: 'validoAte', sortable: true, wrap: true, compact: true, },
  { name: 'Criado em', selector: 'createdAt', sortable: true, compact: true, },
  // { name: 'Ativo', selector: 'ativo', sortable: true, right: true, omit: false, compact: true, cell: row => <> {row.ativo === 1 ? 'X' : row.ativo}</> },
  { name: 'Ações', compact: true, cell: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>Edit</small></Button>}</>, right: true },
]
const handleButtonClick = (state) => {
  window.location = "/editar-motorista/" + state.id;
};

class Usuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuarios: [],
      message: ""
    };

  }

  handleLogout = () => {

  }

  componentDidMount() {
    this.getUsuarios();
  }

  getUsuarios() {
    UsuarioDataService.getAll()
      .then(response => {
        this.setState({
          usuarios: response.data
        });
      })
      .catch(e => {
        //console.log(e);
      });
  }


  render() {


    return (

      <div className="page-wrapper" >
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Usuários" ></Header>
        <section className="course-one course-one__teacher-details home-one">
          <Container>
            {this.state.usuarios.length > 0
              ?
              <>
                <Container fluid={true}>
                  <Row className={"pull-right"}>
                    <Col className={"py-2 "}>
                      <Link to="/add-motorista" className="btn pull-right btn-info botao-acao" >+</Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DataTableExtensions exportHeaders={true}
                        columns={columns}
                        data={this.state.usuarios}>
                        <DataTable
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination
                          highlightOnHover
                        />
                      </DataTableExtensions>
                    </Col>
                  </Row>
                </Container>
              </>
              :
              <p>Carregando usuarios</p>
            }
          </Container>{/*<!-- /.container -->*/}
        </section>

        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }
}
export default Usuarios;