import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import * as XLSX from 'xlsx'
import SinonimoDataService from "../services/sinonimo.service";

import Header from './Common/Header';
import NavTop from './Common/NavTop';
import NavMain from './Common/NavMain';
import Footer from './Common/Footer';
import FooterMenu from './Common/FooterMenu';
// import BannerHome from './BannerHome';
// import BannerPlanos from './BannerPlanos';
// import MailChimp from './mailchimp';
// import SejaBemVindo from './SejaBemVindo';
// import Fase01 from './Fase01';
// import Planos from './Planos';
export default class ImportSinonimo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            Aguardando: true,
            itemserror: [],
            count: 0,
            counterror: 0,
            countgeneral: 0,
            countskip: 0
        }

    }
    SalvarSinonimos(data) {
        this.setState({ countgeneral: this.state.countgeneral + 1 });
        this.setState({ Aguardando: false });
        if (data.Idioma !== 'Idioma' && data.descricao !== 'descricao' && data.descricao !== '') {
            SinonimoDataService.create(data)
                .then(response => {
                    this.setState({ items: this.state.items.concat(response.data) })
                    this.setState({ count: this.state.count + 1 });

                })
                .catch(e => {
                    // console.log(e);
                    this.setState({ itemserror: this.state.itemserror.concat(data) })
                    this.setState({ counterror: this.state.counterror + 1 });
                });
        }
        else { this.setState({ countskip: this.state.countskip + 1 }); }
    }
    render() {


        const readExcel = (file) => {
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file)

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: 'buffer' });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];

                    const data = XLSX.utils.sheet_to_json(ws);
                    resolve(data);
                };
                fileReader.onerror = (error) => {
                    reject(error);
                }
            })
            promise.then((data) => {
                var stringified = JSON.stringify(data);
                stringified = stringified.replaceAll('Outros nomes', 'descricao')
                    .replaceAll('"IDIOMA"', '"Idioma"');
                var jsonObject = JSON.parse(stringified);
                // console.log(jsonObject);
                jsonObject.map(d => (this.SalvarSinonimos(d)));

            })


        }

        return (
            <div className="page-wrapper" >
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Importação de Outros nomes" ></Header>
                <section className="course-one course-one__teacher-details home-one pt-1 ">
                    <Container >

                        <div className="container p-2 ">
                            {this.state.Aguardando
                                ?
                                <div className="row">
                                    <div className="col-12 border p-2">
                                        <input type="file" onChange={(e) => { const file = e.target.files[0]; readExcel(file); }}></input>
                                    </div>
                                </div>
                                : <></>
                            }
                            <div className="row">
                                <div class="col-12 progress" style={{ height: 5 }}>
                                    <div class="progress-bar" role="progressbar" style={{ width: (100 - ((this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror) * 100 / this.state.countgeneral)).toString().concat('%') }} aria-valuenow={((this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror) * 100 / this.state.countgeneral).toString()} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-12 border">
                                    {/* <>{(100 - ((this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror) * 100) / this.state.countgeneral).toString()}</><br></br> */}
                                    <>{this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror === 0 && this.state.Aguardando === false ? "Processamento concluído" : "Aguarde.." || (100 - ((this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror) * 100 / this.state.countgeneral)).toString()}</><br></br>
                                    Registros lidos: {this.state.countgeneral}- {this.state.countgeneral - this.state.countskip - this.state.count - this.state.counterror}<br></br>
                                    Registros pulados: {this.state.countskip}<br></br>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-6 border">

                                    Registros Processado: {this.state.count}<br></br>
                                    {/* Itens Processados */}
                                    <table>
                                        {/* {
                                            this.state.items.map(d => (
                                                <tr className="col-12" key={d.id}>
                                                    <td>{d.id || d.Id}</td>
                                                    <td>{d.descricao}</td>
                                                </tr>
                                            ))
                                        } */}
                                    </table>
                                </div>
                                <div className="col-6 border">
                                    Registros com Erros: {this.state.counterror}<br></br>
                                    Itens com erro
                                    <table>
                                        {
                                            this.state.itemserror.map(d => (
                                                <tr className="col-12" key={d.id}>
                                                    {/* <td>{d.id}</td> */}
                                                    <td>{d.descricao}</td>
                                                </tr>
                                            ))
                                        }
                                    </table>
                                </div>
                            </div>
                            <div className="row">

                            </div>
                        </div>
                    </Container>
                </section>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
